import React       from 'react';
import { graphql } from 'gatsby';

import LegalPage from '../../components/templates/page/legal';

const LegalPageTemplate = props => (<LegalPage {...props} />);

export const query = graphql`
    query($slug: String!) {
        mdx(frontmatter: {slug: {eq: $slug}}) {
            frontmatter {
                title
            }
            body
        }
        eRecht24Imprint {
            html_de
            warnings
        }
        eRecht24PrivacyPolicy {
            html_de
            warnings
        }
    }
`;

export default LegalPageTemplate;
