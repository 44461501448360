import styled from '@emotion/styled';

const Typography = styled.div`
  p {
    line-height: var(--chakra-lineHeights-base);
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
  ul,
  ol {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  ul li,
  ol li {
    line-height: var(--chakra-lineHeights-base);
  }
  ul ul,
  ol ul,
  ul ol,
  ol ol {
    margin-top: 0;
    margin-bottom: 0;
  }
  blockquote {
    line-height: var(--chakra-lineHeights-base);
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  /* Headings */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    /* Change heading typefaces here */
    font-family: sans-serif;
    margin-top: 1.5rem;
    margin-bottom: 0;
    line-height: var(--chakra-lineHeights-base);
  }
  h1 {
    font-size: 4rem;
    line-height: var(--chakra-lineHeights-10);
    margin-top: 3rem;
  }
  h2 {
    font-size: 3rem;
    line-height: var(--chakra-lineHeights-taller);
    margin-top: 3rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.2rem;
  }
  h6 {
    font-size: 1rem;
  }
  /* Tables */
  table {
    margin-top: 1.5rem;
    border-spacing: 0;
    border-collapse: collapse;
  }
  table td,
  table th {
    padding: 0;
    line-height: 33px;
  }
  /* Code blocks */
  code {
    vertical-align: bottom;
  }
`;

export default Typography;