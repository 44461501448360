import React                   from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { MDXRenderer }         from 'gatsby-plugin-mdx';

import LegalSidebar  from '@interness/web-core/src/components/templates/LegalSidebar/LegalSidebar';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import SEO           from '@interness/web-core/src/components/modules/SEO/SEO';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import MoreExpander  from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import InfoBlock     from '@interness/web-core/src/content/legal/InfoBlock';
import InternessLogo from '@interness/web-core/src/content/InternessLogo';

import Typography from '../../text/Typography/Typography';

const LegalPage = props => {
  const mapping = {
    impressum: 'eRecht24Imprint',
    datenschutz: 'eRecht24PrivacyPolicy',
  }
  const legalData = props.data[mapping[props.pageContext.legalType]];

  if (legalData) {
    return (
      <>
        <SEO title={props.data.mdx.frontmatter.title}/>
        <Wrapper>
          <Spacer/>
          {process.env.NODE_ENV === 'development' && legalData.warnings &&
          <>
            <MoreExpander title="Warnungen (nur in Entwicklung sichtbar)">
              <div style={{ backgroundColor: '#e74c3c', color: '#fff', padding: '40px' }}
                   dangerouslySetInnerHTML={{ __html: legalData.warnings }}/>
            </MoreExpander>
            <Spacer/>
          </>}
          <div dangerouslySetInnerHTML={{ __html: legalData.html_de }}/>
          <InfoBlock id="rolex"/>
          {props.pageContext.legalType === 'datenschutz' &&
          <InfoBlock id="consentmanager"/>
          }
          {props.pageContext.legalType === 'impressum' &&
          <>
            <InfoBlock id="additional_top_info"/>
            <InfoBlock id="additional_bottom_info"/>
            <InfoBlock id="image_sources"/>
            <InfoBlock id="insurance"/>
            <InfoBlock id="legal_authority"/>
            <h3>Umsetzung und Unterstützung</h3>
            <InternessLogo/>
          </>
          }
        </Wrapper>
      </>
    )
  } else {
    return (
      <>
        <SEO title={props.data.mdx.frontmatter.title}/>
        <Wrapper>
          <Spacer/>
          <Container fluid>
            <Row>
              <Col md={4}>
                <LegalSidebar/>
              </Col>
              <Col md={8}>
                <h1>{props.data.mdx.frontmatter.title}</h1>
                <MDXRenderer>
                  {props.data.mdx.body}
                </MDXRenderer>
              </Col>
            </Row>
          </Container>
        </Wrapper>
      </>
    )
  }
};

export default LegalPage;
