import React                      from 'react';
import PropTypes                  from 'prop-types';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import AnimateHeight              from 'react-animate-height';

import * as S from './styles';

class MoreExpander extends React.Component {

  static propTypes = {
    title: PropTypes.string,
    separatorColor: PropTypes.string,
  };

  static defaultProps = {
    title: 'mehr erfahren...',
    separatorColor: '#efefef'
  };

  state = {
    height: 0,
  };

  toggle = () => {
    this.setState({
      height: this.state.height === 0 ? 'auto' : 0,
    })
  };

  render() {
    return (
      <div>
        <S.Header height={this.state.height} onClick={this.toggle}>
          {this.state.height !== 0 ? <FaAngleUp/> : <FaAngleDown/>}
          {this.props.title}
        </S.Header>
        <AnimateHeight height={this.state.height} duration={500} style={{borderBottom: `1px solid ${this.props.separatorColor}`}}>
          {this.props.children}
        </AnimateHeight>
      </div>
    );
  }
}

export default MoreExpander;